<template>
  <div class="mt-5">
    <div class="field grid">
      <CalendarIcon class="icon mt-1" :size="35" />
      <div class="col p-0 align-content-center">
        <p class="m-0 p-0 ml-2 text-sm font-bold">Samstag</p>
        <p class="m-0 p-0 ml-2 text-lg font-bold">15.10.22</p>
      </div>
    </div>
    <div class="field grid">
      <ClockIcon class="icon mt-1" :size="35" />
      <div class="col p-0 align-content-center">
        <p class="m-0 p-0 mt-2 ml-2 text-lg font-bold ">18:00</p>
      </div>
    </div>
    <div class="field grid">
      <TimerSandIcon class="icon mt-2" :size="35" />
      <div class="col p-0 align-content-center">
        <vue-countdown :time="new Date('2022-10-15T18:30:00') - new Date()" v-slot="{ days, hours ,minutes }">
          <p class="text-lg ml-2 font-bold m-0">{{ days }} Tage</p>
          <p class="text-xs ml-2 font-bold m-0">{{ hours }} Stunden</p>
          <p class="text-xs ml-2 font-bold m-0">{{ minutes }} Minuten</p>
        </vue-countdown>
      </div>
    </div>
    <div class="field grid">
      <MapMarkerIcon class="icon mt-2" :size="35"/>
      <div class="col p-0 align-content-center">
        <a href="https://www.google.com/maps/dir/?api=1&destination=Design%20Center%20Linz" class="text-lg font-bold m-0 ml-2">Design Center</a>
        <p class="text-base m-0 ml-2">Europaplatz 1</p>
        <p class="text-base m-0 ml-2">4020 Linz</p>
      </div>
    </div>
  </div>
</template>

<script>
import VueCountdown from '@chenfengyuan/vue-countdown'
import CalendarIcon from 'vue-material-design-icons/Calendar.vue';
import ClockIcon from 'vue-material-design-icons/ClockTimeThreeOutline.vue';
import TimerSandIcon from 'vue-material-design-icons/TimerSand.vue';
import MapMarkerIcon from 'vue-material-design-icons/MapMarker.vue';

export default {
  name: 'LocationComponent',
  components: {
    VueCountdown,
    CalendarIcon,
    ClockIcon,
    TimerSandIcon,
    MapMarkerIcon
}
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
a:link {
  color: #81D4FA;
}

/* visited link */
a:visited {
  color: #FFE082;
}

/* mouse over link */
a:hover {
  color: #FFE082;
}

/* selected link */
a:active {
  color:  #FFE082;
}
</style>

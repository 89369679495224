<template>
    <div class="grid">
      <div class="flex col-7 p-2">
        <div class="flex flex-column">
          <p class="text-justify text-sm font-italic m-0">"Jetzt muss ich euch noch schnell was sagen: Nicht des Schenkens wegen seid ihr eingeladen. Kommt mit guter Laune und viel Zeit, dann macht ihr mir die größte Freud. Wollt ihr trotzdem etwas schenken,
          braucht ihr über‘s „wie und was“ nicht nachzudenken.
          Denn um keine Party zu verpassen, möcht ich euer Geld für diese Uhr verprassen."
          </p>
          <p class="text-justify text-xs mt-3">(Bettina kommt auf euch zu)</p>
        </div>
      </div>
      <div class="flex col-5 p-2">
        <img id="image" class="h-20rem" alt="watch" src="../assets/watch.png"/>
      </div>
    </div>  
</template>

<script>
export default {
  name: 'PresentComponent',
  components: {
    
  }
}
</script>

<style scoped>
</style>

<template>
<div class="flex flex-column confetti-container h-screen">
    <div class="flex align-items-center justify-content-center top p-4">
      <img id="image" alt="invite" src="./assets/invite.png"/>
    </div>
    <div class="flex align-items-start justify-content-center bottom z-1">
      <Carousel class="w-24rem z-1" :value="pages" :page="currentPage" :numVisible="1" :numScroll="1" orientation="horizontal" @update:page="pageChanged">
        <template #item="slotProps">
          <div class="flex align-items-center p-0 justify-content-center z-1">
            <Home v-if="slotProps.data === 'home'"/>
            <Location v-if="slotProps.data === 'location'"/>
            <Registration v-if="slotProps.data === 'registration'"/>
            <Present v-if="slotProps.data === 'present'"/>
          </div>
        </template>
      </Carousel>
    </div>
</div>
</template>

<script>
import Confettiful from './confetti.js'

import Carousel from 'primevue/carousel'

import Home from './components/Home.vue'
import Location from './components/Location.vue'
import Registration from './components/Registration.vue'
import Present from './components/Present.vue'

export default {
  name: 'App',
  components: {
    Carousel,
    Home,
    Location,
    Registration,
    Present
},
  data() {
		return {
      currentPage: 0,
			pages: ["home", "location", "registration", "present"]
		}
	},
  created () {
    document.title = "30er Party";
  },
  mounted() {
    this.startConfetti()
    this.restorePage()
  },
  methods: {
    pageChanged(newPage) {
      this.currentPage = newPage
      localStorage.setItem("currentPage", this.currentPage)
    },
    restorePage() {
      var storedPage = Number(localStorage.getItem("currentPage"))
      if(storedPage == null) {
        storedPage = 0
        localStorage.setItem("currentPage", storedPage)
      }
      this.currentPage = storedPage
    },
    startConfetti() {
      window.confettiful = new Confettiful(document.querySelector('.confetti-container'));
    }
  }
}
</script>

<style lang="scss">
@import './confetti.scss';

body {
  margin: 0px;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  background-color: #050207;
  font-family: var(--font-family);
  font-weight: normal;
  color: var(--text-color);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.top {
  height: 40%;
}
.bottom {
  height: 60%;
}
img {
  object-fit: contain;
  max-width: 100%;
  max-height: 100%;
  width: auto;
  height: auto;
}
.icon {
  color: var(--primary-color);
}
</style>

<template>
  <div>
    <div class="field mb-3">
      <h5 class="question question mt-0 mb-2">Wie nenne ich dich?</h5>
      <InputText class="w-full" type="text" v-model="name" @change="onChange" />
    </div>
    <div class="field mb-3">
      <h5 class="question m-0 mb-2">Bist du dabei ?</h5>
      <div class="field-radiobutton mb-2">
        <RadioButton id="attend-yes" name="attending" value="y" v-model="attend" @change="onChange" />
        <label class="text-base"  for="attend-yes">Auf jeden Fall 🥳</label>
      </div>
      <div class="field-radiobutton mb-2">
        <RadioButton id="attend-no" name="attending" value="n" v-model="attend" @change="onChange" />
        <label class="text-base"  for="attend-no">Nein ich mag keine Parties 😴</label>
      </div>
    </div>
    <div class="field">
      <h5 class="question m-0 mb-2">Kommt dein Schatzi mit ?</h5>
      <div class="field-radiobutton mb-2">
        <RadioButton id="partner-yes" name="partner" value="y" v-model="partner" @change="onChange" />
        <label class="text-base"  for="partner-yes">Ja klar 🥰</label>
      </div>
      <div class="field-radiobutton mb-2">
        <RadioButton id="partner-no" name="partner" value="n" v-model="partner" @change="onChange" />
        <label class="text-base"  for="partner-no">Leider nicht 😢</label>
      </div>
      <div class="field-radiobutton mb-2">
        <RadioButton id="partner-who" name="partner" value="q" v-model="partner" @change="onChange" />
        <label class="text-base"  for="partner-who">Schatzi? 🤨</label>
      </div>
    </div>
    <Button class="w-full" :disabled="disabled" :label="info" @click="onConfirm()"></Button>   
  </div>
</template>

<script>
import InputText from 'primevue/inputtext';
import RadioButton from 'primevue/radiobutton';
import Button from 'primevue/button';

import { getAttendee, updateAttendee } from '../fireStore'

export default {
  name: 'RegistrationComponent',
  components: {
    InputText,
    RadioButton,
    Button
  },
  data() {
		return {
      name: null,
      attend: null,
      partner: null,
      info: null,
      disabled: null
		}
	},
  methods: {
    onChange() {
      updateAttendee(this.name, this.attend, this.partner)
      this.refreshAttendance(true)
    },
    onConfirm() {
      updateAttendee(this.name, this.attend, this.partner)
      this.refreshAttendance(false)
    },
    refreshAttendance(changed){
      var filled = this.name != null && this.name.length > 0 && this.attend != null && this.partner != null
      var notFilled = this.name == null || this.name.length == 0 || this.attend == null || this.partner == null
      
      if(notFilled == true){
        this.info = "Bitte ausfüllen"
        this.disabled = true
      }
      if(filled == true && changed == true){
        this.info = "Absenden"
        this.disabled = false
      }
      if(filled == true && changed == false){
        this.info = "Danke fürs Bescheid geben !"
        this.disabled = true
      }
    },
    async loadAttendee() {
      let attendee = await getAttendee()
      if(attendee != null){
          this.name = attendee.name
          this.attend = attendee.attend
          this.partner = attendee.partner
      }
      this.refreshAttendance(false)
    }
  },
  beforeMount() {
    this.loadAttendee()
  }
}
</script>

<style lang="scss" scoped>
.question {
  color: var(--primary-color);
}
.p-inputtext{
  border: 1px solid var(--primary-color);
}
</style>

import firebase from 'firebase/compat/app';
import 'firebase/compat/firestore';
import { v4 as uuidv4 } from 'uuid';

const firebaseConfig = {
  apiKey: "AIzaSyAFwEF5lDvgH4sgogca20hKmRlmGslg2bM",
  authDomain: "birthday-9f739.firebaseapp.com",
  projectId: "birthday-9f739",
  storageBucket: "birthday-9f739.appspot.com",
  messagingSenderId: "1098165938460",
  appId: "1:1098165938460:web:bfb976ad594d09221db2d9"
};

const db = firebase.initializeApp(firebaseConfig).firestore();

function getAttendeeId() {
  var attendeeId = localStorage.getItem('attendeeId')
  if(attendeeId == null){
    console.log("Init attendeeId")
    attendeeId = uuidv4()
    localStorage.setItem('attendeeId', attendeeId)
  }
  return attendeeId 
}

export async function getAttendee() {
  let attendeeId = getAttendeeId()
  let snapshot = await db.collection('attendees').doc(attendeeId).get()
  return snapshot.data()
}

export async function updateAttendee(name, attend, partner) {
  let attendeeId = getAttendeeId()
  await db.collection("attendees").doc(attendeeId).set({
    name: name,
    attend: attend,
    partner: partner
  })
}